<template>
  <div class="backend-factoring-autocomplete">
    <v-text-field
      v-model="search"
      label="Factoring Company"
      :loading="loading"
    />
    <div class="backend-factoring-autocomplete__popover" v-if="factoringCompanies.length">
      <div
        v-for="factoringCompany in factoringCompanies"
        :key="factoringCompany.id"
        class="backend-factoring-autocomplete__popover__item"
        @click="selectFactoringCompany(factoringCompany)"
      >
        <span>{{ factoringCompany.businessName }}</span>
        <span>{{ factoringCompany.addressLine1 }}</span>
      </div>
    </div>
  </div>
</template>

<script>
import debounce from "lodash/debounce"
import api from "@/api/api"
import common from "@/utils/common"

const snackbarType = common.data.snackbarType

export default {
  name: "FactoringAutoComplete",
  props: {
    factoringCompanyName: {
      type: String,
      default: ""
    },
    showItems: {
      type: Number,
      default: 10
    }
  },
  data() {
    return {
      loading: false,
      search: "",
      factoringCompanies: [],
      isEnterEvent: false,
    }
  },
  computed: {
    factoringCompanyList() {
      return this.factoringCompanies.map((company) => {
        const name = company.text
        return Object.assign({}, company, { name })
      })
    },
  },
  watch: {
    search(val) {
      if (!val || val.length === 0 || val === this.factoringCompanyName) {
        this.factoringCompanies = []
        return
      }

      if (this.loading) return
      this.loading = true
      this.getSearch()
    },
    factoringCompanyName(val) {
      this.search = val
    }
  },
  mounted(){
    window.addEventListener("mousedown", this.handleOutsideClick)
  },
  methods: {
    getSearch: debounce(function () {
      api
        .get(
          `${process.env.VUE_APP_BASE_URL}/accounts/api/factoring/autocomplete/`,
          {
            params: {
              q: this.search,
              limit: this.showItems
            },
          }
        )
        .then((res) => {
          if (!this.search) return

          this.factoringCompanies = common.methods.toCamelCase(res.data.results)
        })
        .catch((err) => {
          const payload = {
            status: true,
            text: "Whoops, something went wrong",
            error: err,
            type: snackbarType.error,
          }
          this.$store.commit("main/SET_SNACKBAR", payload)
        })
        .finally((this.loading = false))
    }, 500),
    selectFactoringCompany(factoringCompany){
      this.factoringCompanies = []
      this.search = this.factoringCompanyName
      this.$emit('factoringCompanySelect', factoringCompany)
    },
    handleOutsideClick(e) {
      if (!this.$el.contains(e.target)) {
        this.factoringCompanies = []
      }
    },
  },
}
</script>

<style lang="scss">
.backend-factoring-autocomplete {
  position: relative;

  &__popover {
    position: absolute;
    top: 70%;
    left: 0;
    width: 100%;
    background-color: #fff;
    border: 1px solid #e0e0e0;
    border-radius: 4px;
    z-index: 2;
    max-height: 180px;
    overflow-y: auto;

    &__item {
      padding: 8px 16px;
      cursor: pointer;
      display: flex;
      flex-direction: column;
      gap: 5px;

      >:last-child {
        font-size: 12px;
        color: #6b6a6a;
      }

      &:hover {
        background-color: #e0e0e0;
      }
    }
  }
}
</style>