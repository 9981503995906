<template>
  <v-row>
    <v-col cols="12">
      <v-container fluid style="max-width: 1600px">
        <v-expansion-panels>
          <v-expansion-panel>
            <v-expansion-panel-header>
              Search
              <span style="padding-left: 2px">({{ totalRecord }})</span>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-row>
                <v-col cols="12" md="3">
                  <v-text-field
                    v-model="searchParam.businessName"
                    type="text"
                    label="Name"
                    @keydown.enter="searchAllFactoringCompanies()"
                  />
                </v-col>
                <v-col cols="12" md="3">
                  <v-text-field
                    v-model="searchParam.city"
                    type="text"
                    label="City"
                    @keydown.enter="searchAllFactoringCompanies()"
                  />
                </v-col>
                <v-col cols="12" md="3">
                  <v-text-field
                    v-model="searchParam.state"
                    type="text"
                    label="State"
                    @keydown.enter="searchAllFactoringCompanies()"
                  />
                </v-col>
                <v-col cols="12" md="3">
                  <v-text-field
                    v-model="searchParam.email"
                    type="text"
                    label="Email"
                    @keydown.enter="searchAllFactoringCompanies()"
                  />
                </v-col>
                <v-col cols="12" md="3">
                  <v-text-field
                    v-model="searchParam.phone"
                    type="text"
                    label="Phone"
                    @keydown.enter="searchAllFactoringCompanies()"
                  />
                </v-col>
              </v-row>
              <v-row>
                <v-col class="xs" cols="12" md="3" sm="4">
                  <v-btn
                    class="blue white--text dark-3"
                    small
                    @click="searchAllFactoringCompanies()"
                  >
                    <v-icon small> mdi-search-web </v-icon>
                    Filter
                  </v-btn>
                  <v-btn class="ml-1" small @click="resetSearch()">
                    Reset
                  </v-btn>
                </v-col>
              </v-row>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-container>
    </v-col>
    <v-col cols="12">
      <v-container id="tblFactoring" fluid class="pt-0 data-container">
        <v-data-table
          id="tblData"
          :headers="headers"
          :items="factoringCompanies"
          class="elevation-1 data-table"
          calculate-widths
          hide-default-footer
          :loading="loadingIcon"
          fixed-header
          :items-per-page="pageSize"
        >
          <template v-slot:item.businessName="{ item }">
            <router-link
              :to="{
                name: 'Factoring Detail',
                params: { id: item.id },
              }"
              class="name-link"
              target="_blank"
            >
              {{ item.businessName }}
            </router-link>
          </template>
          <template v-slot:item.actions="{ item }">
            <v-menu
              :close-on-content-click="false"
              offset-x
              absolute
              :nudge-width="250"
              @input="!$event && (replacementSourceFactoringComapny = replacementTargetFactoringCompany = null)"

            >
              <template v-slot:activator="{ on }">
                <v-icon
                  @click="void(0)"
                  v-on="on"
                >
                  mdi-file-replace-outline
                </v-icon>
              </template>

              <div class="auto-complete-container">
                <b>Select replacement factoring company</b>
                <factoring-auto-complete
                  :factoringCompanyName="(replacementTargetFactoringCompany || {}).businessName || ''"
                  @factoringCompanySelect="selectFactoringCompany(item, $event)"
                />

                <div>
                  Source: {{ item.businessName }} - {{ item.addressLine1 }}
                </div>
                <div>
                  Target: {{ 
                    !replacementTargetFactoringCompany ?
                    "Not selected" :
                    `${replacementTargetFactoringCompany.businessName} - ${replacementTargetFactoringCompany.addressLine1}` 
                  }}
                </div>

                <v-btn
                  class="blue white--text dark-3"
                  small
                  @click="replaceFactoringCompany()"
                  :disabled="!replacementTargetFactoringCompany"
                  :loading="isReplacing"
                > Replace </v-btn>
              </div>
            </v-menu>
          </template>
          <template v-slot:footer>
            <v-page
              :total-row="totalRecord"
              :page-size-menu="[10, 20, 50, 100]"
              class="pt-3 pr-3 pb-3"
              @page-change="readDataFromAPI"
            />
          </template>
        </v-data-table>
      </v-container>
    </v-col>
  </v-row>
</template>

<script>
import { mapGetters } from "vuex"
import FactoringAutoComplete from './components/FactoringAutoComplete'
import api from "@/api/api"

export default {
  name: "AllFactoringCompany",
  components: {
    FactoringAutoComplete
  },
  data() {
    return {
      totalRecord: 0,
      searchParam: {
        name: null,
        city: null,
        state: null,
        email: null,
        phone: null,
      },
      headers: [
        { text: "Name", align: "start", value: "businessName" },
        { text: "Contact Name", value: "contactName", align: "start" },
        { text: "Address", value: "addressLine1", align: "start" },
        { text: "City", value: "city", align: "start" },
        { text: "State", value: "state", align: "center" },
        { text: "Phone", value: "phone", align: "start" },
        { text: "Email", value: "email", align: "start" },
        { text: "Actions", value: "actions", align: "center" },
      ],
      queryParams: {},
      factoringCompanies: [],
      numberOfPages: 0,
      pageSize: 10,
      page: 1,
      replacementTargetFactoringCompany: null,
      replacementSourceFactoringComapny: null,
      isReplacing: false,
    }
  },
  computed: {
    ...mapGetters("factoring", {
      factoringList: "factoringList",
    }),
    ...mapGetters("generic", {
      loadingIcon: "loadingIcon",
    }),
  },
  watch: {
    factoringList() {
      this.totalRecord = this.factoringList.count
      this.factoringCompanies = this.factoringList.results
      this.numberOfPages = Math.ceil(this.totalRecord / this.pageSize)
    },
  },
  mounted() {
    this.factoringCompanies = []
    this.getFactoringCompany()
  },
  methods: {
    getFactoringCompany() {
      this.queryParams.offset = 0
      this.queryParams.limit = this.pageSize
      this.$store.dispatch(
        "factoring/GET_FACTORING_COMPANIES",
        this.queryParams
      )
    },
    resetSearch() {
      this.searchParam.businessName = null
      this.searchParam.city = null
      this.searchParam.state = null
      this.searchParam.email = null
      this.searchParam.phone = null
      this.factoringCompanies = []
      this.queryParams = {}
      this.getFactoringCompany()
    },
    createQueryString() {
      this.queryParams = {}
      this.queryParams.limit = 10
      this.queryParams.offset = 0
      if (this.searchParam.businessName) {
        this.queryParams.business_name__icontains = this.searchParam.businessName
      }
      if (this.searchParam.city) {
        this.queryParams.city__icontains = this.searchParam.city
      }
      if (this.searchParam.state) {
        this.queryParams.state__icontains = this.searchParam.state
      }
      if (this.searchParam.email) {
        this.queryParams.email__icontains = this.searchParam.email
      }
      if (this.searchParam.phone) {
        this.queryParams.phone__icontains = this.searchParam.phone
      }
    },
    searchAllFactoringCompanies() {
      this.locationList = []
      this.createQueryString()
      this.$store.dispatch(
        "factoring/GET_FACTORING_COMPANIES",
        this.queryParams
      )
    },
    readDataFromAPI(value) {
      if (this.factoringCompanies.length > 0) {
        this.page = value.pageNumber
        this.pageSize = value.pageSize
        let pageNumber = this.pageSize * this.page - this.pageSize
        if (pageNumber < 0) {
          pageNumber = 0
        }
        this.queryParams.offset = pageNumber
        this.queryParams.limit = this.pageSize
        this.$store.dispatch(
          "factoring/GET_FACTORING_COMPANIES",
          this.queryParams
        )
      }
    },
    selectFactoringCompany(source, target) {
      this.replacementSourceFactoringComapny = source
      this.replacementTargetFactoringCompany = target
    },
    replaceFactoringCompany(){
      if (!this.replacementSourceFactoringComapny || !this.replacementTargetFactoringCompany) {
        return
      }

      this.isReplacing = true
      api
        .post(
          `${process.env.VUE_APP_BASE_URL}/accounts/api/factoring/replace/`,
          {
            source_id: this.replacementSourceFactoringComapny.id,
            target_id: this.replacementTargetFactoringCompany.id,
          }
        ).then(() => {
          this.replacementTargetFactoringCompany = null
          this.replacementSourceFactoringComapny = null
          this.getFactoringCompany()
        }).finally(() => {
          this.isReplacing = false
        })
    }
  },
}
</script>

<style scoped>
.v-data-table /deep/ .sticky-header {
  position: sticky;
  top: var(--toolbarHeight);
}

.v-data-table /deep/ .v-data-table__wrapper {
  overflow: unset;
}
.v-data-table /deep/ .v-data-table__wrapper /deep/ .v-data-table-header th {
  background-color: #f6f6f6;
}
.v-data-table /deep/ .v-data-table__wrapper /deep/ .mdi-close-box {
  color: #ffa500 !important;
}
.name-link {
  text-decoration: none;
  cursor: pointer;
  color: #000000;
}
.name-link:hover {
  text-decoration: none;
}
.auto-complete-container {
  background-color: white;
  padding: 10px;
  min-height: 100px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  height: 250px;
}

.auto-complete-container > .v-btn {
  margin-top: auto !important;
}
</style>
<style>
select {
  -webkit-appearance: auto;
  -moz-appearance: auto;
}
.v-pagination {
  border-top: thin solid rgba(0, 0, 0, 0.12);
}
</style>
